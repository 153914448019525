var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.title,
        "z-index": 8,
        closable: true,
        "overflow-auto": "",
        "mask-closable": false,
      },
      on: { ok: _vm.handleOk, cancel: _vm.cancel },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        _vm._l(_vm.dataList, function (item, index) {
          return _c(
            "div",
            { key: item.onJobId, staticClass: "list" },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "user" },
                  [
                    _c("CbAvater", {
                      attrs: {
                        "avater-name": item.staffName,
                        "on-job-id": item.onJobId,
                        "staff-id": item.staffId,
                      },
                    }),
                    _c("div"),
                  ],
                  1
                ),
              ]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "230px" },
                  attrs: {
                    placeholder: _vm.placeholder,
                    disabled: item[_vm.replaceIgnoreTypeKey],
                  },
                  model: {
                    value: item[_vm.replaceGroupKey],
                    callback: function ($$v) {
                      _vm.$set(item, _vm.replaceGroupKey, $$v)
                    },
                    expression: "item[replaceGroupKey]",
                  },
                },
                _vm._l(_vm.selectList, function (select, i) {
                  return _c(
                    "a-select-option",
                    {
                      key: i,
                      attrs: { value: select[_vm.replaceFields.value] },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(select[_vm.replaceFields.label]) + " "
                      ),
                    ]
                  )
                }),
                1
              ),
              _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.editStaffIgnoreType(item, index)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(item[_vm.replaceIgnoreTypeKey] ? "撤销忽略" : "忽略")
                  ),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }